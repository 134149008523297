import React from 'react'

function NoiseOverlay() {
  return (
    <div className='noisey-overlay-container'>
        <div className='noisey-overlay-effect'/>
    </div>
  )
}

export default NoiseOverlay